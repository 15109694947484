@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
:root {
  --app-height: 100vh;
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
  max-height: calc(100vh - 60px) !important;
  overflow-y: auto !important;
}

test_in_progress {
  background-color: yellow;
  width: 10px;
  height: 10px;
  display: block;
}

test_ok {
  background-color: greenyellow;
  width: 10px;
  height: 10px;
  display: block;
}

test_error {
  background-color: red;
  width: 10px;
  height: 10px;
  display: block;
}

body, header, footer {
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

ngb-modal-backdrop.modal-backdrop.fade.show {
  z-index: 1040 !important;
}

span.visually-hidden.ng-star-inserted {
  display: none !important;
}

span.visually-hidden {
  display: none !important;
}

html {
  height: auto !important;
}

body {
  height: auto !important;
  padding-bottom: 0 !important;
  background-color: #3082ca !important;
}

#mainBodyDiv {
  position: relative;
  min-height: 100vh;
  background-color: white !important;
}

#content-wrap {
  min-height: 100vh;
}

.bottompadded {
  padding-bottom: 30px; /* Footer height */
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px; /* Footer height */
}

.container, .container-fluid {
  margin-top: 20px;
  max-width: 4000px !important;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.App {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.alert_error {
  background-color: red !important;
  color: white !important;
}

.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
}

.sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 260px;
  display: block;
  z-index: 10;
  color: #fff;
  font-weight: 200;
  background-size: cover;
  background-position: 50%;
  transition: ease all 0.5s;
}

.sidebar:before, body > .navbar-collapse:before {
  opacity: 0.01;
  background: #000;
}

.sidebar:after, .sidebar:before, body > .navbar-collapse:after, body > .navbar-collapse:before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.sidebar .sidebar-background, body > .navbar-collapse .sidebar-background {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: 100%;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-color: #368ad0;
  background-image: url(assets/images/menubkg.png);
  background-blend-mode: color-burn;
}

.sidebar .nav li .nav-link svg,
.sidebar .nav li .nav-link img,
body > .navbar-collapse .nav li .nav-link svg,
body > .navbar-collapse .nav li .nav-link img {
  font-size: 28px;
  margin-right: 15px;
  width: 30px;
  text-align: center;
  vertical-align: middle;
  float: left;
}

.sidebar .sidebar-wrapper, body > .navbar-collapse .sidebar-wrapper {
  position: relative;
  max-height: calc(100vh - 75px);
  min-height: 100%;
  overflow: auto;
  width: 260px;
  z-index: 4;
}

.sidebar .logo, body > .navbar-collapse .logo {
  padding: 10px 15px;
  border-bottom: 1px solid hsla(0deg, 0%, 100%, 0.2);
}

.sidebar .logo, body > .navbar-collapse .logo {
  padding: 10px 15px 9px;
  position: relative;
  z-index: 4;
}

.sidebar .logo .simple-text, body > .navbar-collapse .logo .simple-text {
  text-transform: uppercase;
  padding: 5px 0;
  display: block;
  font-size: 18px;
  color: #fff;
  text-align: center;
  font-weight: 400;
  line-height: 30px;
}

.sidebar .nav .navbar-nav {
  width: 260px;
}

.sidebar .nav li.active .nav-link,
.sidebar-mini .nav li.active .nav-link,
body > .navbar-collapse .nav li.active .nav-link {
  color: #fff;
  opacity: 1;
  background: hsla(0deg, 0%, 100%, 0.23);
}

.sidebar .nav li.active .nav-link-mini,
body > .navbar-collapse .nav li.active .nav-link-mini {
  cursor: pointer;
  width: 130px;
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  font-size: 10px;
  text-align: center;
}

.sidebar .nav li .nav-link-mini, body > .navbar-collapse .nav li .nav-link-mini {
  color: #fff;
  margin: 0;
  opacity: 0.86;
  border-radius: 4px;
  display: block;
  padding: 10px 15px;
  width: 130px;
  float: left;
  text-transform: uppercase;
  font-size: 10px;
  text-align: center;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.sidebar .nav li .nav-link,
.sidebar-mini .nav li .nav-link,
body > .navbar-collapse .nav li .nav-link {
  color: #fff;
  margin: 5px 15px;
  opacity: 0.86;
  border-radius: 4px;
  display: block;
  padding: 10px 15px;
  cursor: pointer;
}

.sidebar .nav .nav-link:hover,
.sidebar .nav .nav-link-mini:hover,
.sidebar-mini .nav .nav-link:hover,
.sidebar.mini .nav .nav-link-mini:hover {
  background: hsla(0deg, 0%, 100%, 0.23);
}

.sidebar .nav a.nav-link,
.sidebar-mini .nav a.nav-link,
body > .navbar-collapse .nav .nav-link {
  color: #fff;
  margin: 5px 15px;
  opacity: 0.86;
  border-radius: 4px;
  text-transform: uppercase;
  line-height: 30px;
  font-size: 12px;
  font-weight: 600;
}

.sidebar .nav a,
.sidebar-mini .nav a,
.table > tbody > tr .td-actions .btn {
  transition: all 0.15s ease-in;
}

.sidebar .nav li a.nav-link p,
.sidebar-mini .nav li a.nav-link p,
body > .navbar-collapse .nav li .nav-link p {
  margin: 0;
  line-height: 31px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-flex;
}

.main-panel {
  overflow: auto;
  max-height: 100%;
  height: 100%;
  transition: ease all 0.5s;
  -webkit-overflow-scrolling: touch;
}

.sidebar {
  overflow-x: hidden;
  max-height: 100%;
  height: 100%;
  transition: ease all 0.5s;
  -webkit-overflow-scrolling: touch;
}

.main-panel {
  background: rgba(203, 203, 210, 0.15);
  position: relative;
  float: right;
  width: calc(100% - 260px);
  min-height: 100%;
  transition: ease all 0.5s;
}

.main-panel-wide {
  background: rgba(203, 203, 210, 0.15);
  position: relative;
  float: right;
  width: 100%;
  height: 100%;
}

.page-navbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.page-navbar {
  margin-top: 20px;
  height: 51px;
}

.page-navbar li.nav-item p {
  font-size: 16px;
}

.page-navbar li.nav-item a {
  cursor: pointer;
  color: #888;
  margin: 0;
  padding: 0;
}

.page-navbar li.nav-item a:visited {
  color: #888;
}

.page-navbar li.nav-item a:hover {
  color: orange;
}

.page-navbar .nav li .nav-link span {
  margin: 0;
  line-height: 28px;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-flex;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
}

.page-navbar .nav li .nav-link svg,
.page-navbar .nav li .nav-link img {
  font-size: 24px;
  margin-right: 5px;
  width: 30px;
  text-align: center;
  vertical-align: middle;
  float: left;
}

.main-panel .navbar {
  margin-bottom: 0;
}

.navbar {
  border: 0;
  font-size: 16px;
  border-radius: 0;
  min-height: 50px;
  background-color: hsla(0deg, 0%, 100%, 0.96);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

div.card-info {
  background-color: #fff;
}

div.card-title {
  margin: 0;
  color: #797777;
  font-weight: 300;
  font-size: 22px;
  line-height: 30px;
  -webkit-font-smoothing: antialiased;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 0;
  background-color: #fff;
  border-bottom: none !important;
}

div.modal-header h5 {
  margin: 0;
  color: #797777;
  font-weight: 300;
  font-size: 22px;
  line-height: 30px;
  -webkit-font-smoothing: antialiased;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 0;
  background-color: #fff;
  border-bottom: none !important;
}

div.card-subtitle, div.modal-body {
  font-size: 14px;
  font-weight: 400;
  color: #9a9a9a;
  padding-left: 15px;
  margin-bottom: 1.5rem;
}

div.modal-body {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

div.card-body {
  padding: 0;
}

.btn-secondary:active, .btn-secondary:focus {
  color: #292b2c !important;
  background-color: #fff !important;
  border-color: #ccc !important;
}

.alert, .brand, .btn-simple, .h1, .h2, .h3, .h4, .h5, .h6, .navbar, .td-name, a, body, button.close, h1, h2, h3, h4, h5, h6, p, td {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
}

.h3, h3 {
  font-weight: 400 !important;
  font-size: 20px !important;
  color: #888;
  margin-left: 10px;
}

.h1, .h2, .h3, .h4, h1, h2, h3, h4 {
  font-weight: 300;
  margin: 30px 0 15px;
}

.page-navbar h3 {
  margin-top: 4px;
  font-weight: 400 !important;
  font-size: 20px !important;
  color: #888;
  margin-left: 20px;
}

.page-navbar .row {
  padding-bottom: 4px;
}

.table > thead > tr > th {
  font-size: 12px;
  text-transform: uppercase;
  color: #9a9a9a;
  font-weight: 400;
  padding-bottom: 5px;
  border-top: none !important;
  border-bottom: none;
  text-align: left !important;
}

button {
  outline: none !important;
}

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
  padding: 12px 8px;
  vertical-align: middle;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

a.function {
  cursor: pointer;
  color: #368ad0 !important;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-size: 12px;
}

a.function:visited {
  color: #368ad0 !important;
}

a.function:hover {
  color: orange !important;
  text-decoration: none;
}

a.function.disabled,
a.function.disabled:hover a.function.disabled:active {
  color: #ddd !important;
  cursor: default;
}

a.function.active,
a.function.active:hover a.function.active:active {
  color: #22588a !important;
  cursor: default;
  text-decoration: underline !important;
}

a.function.selected,
a.function.selected:hover a.function.selected:active {
  color: #22588a !important;
  cursor: default;
  font-weight: 700;
}

ol.breadcrumb {
  background-color: transparent !important;
  margin-top: -8px;
}

ol.breadcrumb li:not(:last-child) {
  margin-right: 20px;
}

a.button {
  cursor: pointer;
  color: #368ad0 !important;
  margin-left: 25px;
  margin-right: 5px;
  padding: 0;
  text-transform: uppercase;
  font-size: 16px;
}

button.button {
  cursor: pointer;
  color: #368ad0 !important;
  margin-left: 25px;
  margin-right: 5px;
  padding: 0;
  text-transform: uppercase;
  font-size: 16px;
  border: 0;
  background: 0;
}

button.button:hover,
button.button:active,
button.button:focus {
  color: orange !important;
  text-decoration: none;
  border: 0;
}

a.button:visited {
  color: #368ad0;
}

a.button:hover {
  color: orange !important;
  text-decoration: none;
}

a.button svg,
a.button img,
a.button i {
  font-size: 20px;
  margin-right: 5px;
  width: 20px;
  text-align: center;
  margin-top: -4px;
}

label {
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 0 !important;
  padding-bottom: 0;
}

.form-group {
  margin-bottom: 5px !important;
}

.main-panel table.dataTable.no-footer {
  border-bottom: 0;
}

.main-panel .dataTables_length,
.main-panel .dataTables_filter,
.main-panel .dataTables_info,
.main-panel .dataTables_paginate {
  margin-left: 10px;
  margin-right: 10px;
  color: #888 !important;
}

.main-panel .dataTables_length label,
.main-panel .dataTables_filter label,
.main-panel .dataTables_info {
  font-size: 12px;
  color: #888 !important;
  text-transform: uppercase;
}

.main-panel .dataTables_wrapper .dataTables_paginate .paginate_button {
  font-size: 12px;
  color: #368ad0 !important;
  text-transform: uppercase;
  margin-right: 5px;
  cursor: pointer;
}

.main-panel .dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.main-panel .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.main-panel .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  color: #ddd !important;
}

.main-panel table.dataTable thead .sorting_asc,
.main-panel table.dataTable thead .sorting,
.main-panel table.dataTable thead .sorting_desc {
  background: none !important;
}

.main-panel table.dataTable thead .sorting_asc:after {
  color: #22588a;
  margin-left: 3.5px;
  content: "↑";
}

.main-panel table.dataTable thead .sorting_desc:after {
  color: #22588a;
  margin-left: 3.5px;
  content: "↓";
}

.main-panel .dataTables_wrapper .dataTables_filter input {
  border: 1px #ddd solid;
  padding-left: 10px;
}

.main-panel .dataTables_wrapper .dataTables_paginate .paginate_button.current,
.main-panel .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  font-size: 14px;
  color: #22588a !important;
  background: transparent;
  border: 0;
}

.main-panel .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  font-size: 12px;
  color: orange !important;
  background: transparent;
}

.main-panel table.dataTable thead th,
.main-panel table.dataTable thead td {
  font-size: 12px;
  text-transform: uppercase;
  color: #9a9a9a;
  font-weight: 400;
  padding-bottom: 5px;
  border-top: none !important;
  border-bottom: none;
  text-align: left !important;
}

.main-panel table.dataTable tbody tr {
  height: 60px;
}

.main-panel table.dataTable tbody tr th,
.main-panel table.dataTable tfoot tr td,
.main-panel table.dataTable tfoot tr th,
.main-panel table.dataTable thead tr td,
.main-panel table.dataTable thead tr th {
  padding: 12px 8px;
  vertical-align: middle;
}

.main-panel table.dataTable tbody tr td {
  font-weight: 400;
  font-size: 14px;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.main-panel input.ng-invalid,
.main-panel select.ng-invalid,
.main-panel textarea.ng-invalid,
.main-panel-wide input.ng-invalid {
  border-color: red;
}

.main-panel input[type=checkbox].ng-invalid + label,
.main-panel-wide input[type=checkbox].ng-invalid + label {
  color: red;
}

.modal-header h4.modal-title {
  margin-top: 4px;
  font-weight: 400 !important;
  font-size: 20px !important;
  color: #888;
  margin-left: 0px;
}

.modal-body p {
  font-size: 16px;
  color: #564d4d;
  font-weight: 400;
  padding-top: 27px;
  padding-bottom: 5px;
  border-top: none !important;
  border-bottom: none;
  text-align: left !important;
}

.modal-content .modal-footer button.button {
  margin-left: 30px;
}

a.linkme {
  color: white;
  width: 250px;
  display: inline-block;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px;
}

a.linkme:hover {
  background: hsla(0deg, 0%, 100%, 0.23);
  text-decoration: none;
}

a.linkme-mini {
  color: white;
  width: 200px;
  margin-left: 0px;
  display: inline-block;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px;
}

a.linkme-mini:hover {
  background: hsla(0deg, 0%, 100%, 0.23);
  text-decoration: none;
}

@media (max-width: 700px) {
  .sidebar {
    width: 0px;
  }
  .main-panel {
    background: rgba(203, 203, 210, 0.15);
    position: relative;
    float: right;
    width: 100%;
    min-height: 100%;
  }
}
.copyright-text::before {
  content: "SIMY © 2022 - Combit Zrt.";
}

@media (max-width: 700px) {
  .sidebar {
    width: 0px;
  }
  .sidebar.show {
    width: 260px;
  }
}
@media (max-width: 530px) {
  .hide-on-small {
    display: none;
  }
}
#navbarSupportedContent.collapsing {
  position: relative;
  width: 0;
  overflow: hidden;
  transition: width 0.35s ease;
}

.registration-page {
  height: 100%;
}
.registration-page .container-fluid {
  width: 710px;
  background-color: #368ad0;
  border-radius: 4px;
  flex-flow: row;
}
@media (max-width: 530px) {
  .registration-page .container-fluid {
    width: 310px;
  }
}
@media (max-width: 730px) and (min-width: 531px) {
  .registration-page .container-fluid {
    width: 510px;
  }
}
.registration-page .container-fluid .container-row {
  flex-flow: column;
}
@media (max-width: 530px) {
  .registration-page .container-fluid .container-row {
    flex-flow: row;
  }
}
.registration-page .container-fluid .container-row .sidebar-wrapper {
  width: 200px;
  float: left;
  height: 100%;
  border-radius: 4px;
  position: relative;
}
.registration-page .container-fluid .container-row .sidebar-wrapper .logo {
  padding-top: 4px;
  height: 75px;
}
.registration-page .container-fluid .container-row .sidebar-wrapper .logo a img {
  width: 200px;
}
.registration-page .container-fluid .container-row .sidebar-wrapper .logo .menuListDiv {
  position: absolute;
  top: 56px;
  width: 100%;
  text-align: right;
}
.registration-page .container-fluid .container-row .sidebar-wrapper .nav {
  margin: 0;
  padding-top: 30px;
}
@media (max-width: 730px) {
  .registration-page .container-fluid .container-row .sidebar-wrapper .nav {
    padding-top: 0;
  }
  .registration-page .container-fluid .container-row .sidebar-wrapper .nav a {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.registration-page .container-fluid .container-row .sidebar-wrapper .parallax_one_copyright_content {
  color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 5px;
}
.registration-page .container-fluid .container-row .card {
  width: 500px;
  height: 100%;
  margin-bottom: 0;
}
@media (max-width: 530px) {
  .registration-page .container-fluid .container-row .card {
    width: 300px;
  }
}
.registration-page .container-fluid .container-row .card .large-margin {
  margin-top: 60px;
  margin-bottom: 27px;
}
.registration-page .container-fluid .container-row .card .card-margin {
  margin-left: 15px;
  margin-right: 15px;
}
.registration-page .container-fluid .container-row .card .card-margin .form-content {
  padding-bottom: 5px;
}
.registration-page .container-fluid .container-row .card .form-content .second-row {
  padding-bottom: 0px;
}
.registration-page .container-fluid .container-row .card .form-button-container {
  margin-top: 20px;
  text-align: right;
  position: relative;
  bottom: 20px;
  right: 20px;
}
.registration-page .container-fluid .container-row .card .parallax_one_copyright_content {
  width: 100%;
}
.registration-page .container-fluid .container-row .card .parallax_one_copyright_content .linkme-mini {
  width: 100%;
  color: #368ad0;
}

.login-page,
.forgotten-password-page {
  height: 100%;
}
.login-page .container-fluid,
.forgotten-password-page .container-fluid {
  width: 510px;
  background-color: #368ad0;
  border-radius: 4px;
  flex-flow: row;
}
@media (max-width: 530px) {
  .login-page .container-fluid,
  .forgotten-password-page .container-fluid {
    width: 310px;
  }
}
.login-page .container-fluid .container-row,
.forgotten-password-page .container-fluid .container-row {
  flex-flow: column;
}
@media (max-width: 530px) {
  .login-page .container-fluid .container-row,
  .forgotten-password-page .container-fluid .container-row {
    flex-flow: row;
  }
}
.login-page .container-fluid .container-row .sidebar-wrapper,
.forgotten-password-page .container-fluid .container-row .sidebar-wrapper {
  width: 200px;
  float: left;
  height: 100%;
  border-radius: 4px;
  position: relative;
}
.login-page .container-fluid .container-row .sidebar-wrapper .logo,
.forgotten-password-page .container-fluid .container-row .sidebar-wrapper .logo {
  padding-top: 4px;
  height: 75px;
}
.login-page .container-fluid .container-row .sidebar-wrapper .logo a img,
.forgotten-password-page .container-fluid .container-row .sidebar-wrapper .logo a img {
  width: 200px;
}
.login-page .container-fluid .container-row .sidebar-wrapper .logo .menuListDiv,
.forgotten-password-page .container-fluid .container-row .sidebar-wrapper .logo .menuListDiv {
  position: absolute;
  top: 56px;
  width: 100%;
  text-align: right;
}
.login-page .container-fluid .container-row .sidebar-wrapper .nav,
.forgotten-password-page .container-fluid .container-row .sidebar-wrapper .nav {
  margin: 0;
  padding-top: 30px;
}
@media (max-width: 530px) {
  .login-page .container-fluid .container-row .sidebar-wrapper .nav,
  .forgotten-password-page .container-fluid .container-row .sidebar-wrapper .nav {
    padding-top: 0;
  }
  .login-page .container-fluid .container-row .sidebar-wrapper .nav a,
  .forgotten-password-page .container-fluid .container-row .sidebar-wrapper .nav a {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.login-page .container-fluid .container-row .sidebar-wrapper .parallax_one_copyright_content,
.forgotten-password-page .container-fluid .container-row .sidebar-wrapper .parallax_one_copyright_content {
  color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 5px;
}
.login-page .container-fluid .container-row .card,
.forgotten-password-page .container-fluid .container-row .card {
  width: 300px;
  height: 100%;
  margin-bottom: 0;
}
.login-page .container-fluid .container-row .card .card-margin,
.forgotten-password-page .container-fluid .container-row .card .card-margin {
  margin-left: 15px;
  margin-right: 15px;
}
.login-page .container-fluid .container-row .card .card-margin .form-content,
.forgotten-password-page .container-fluid .container-row .card .card-margin .form-content {
  padding-bottom: 5px;
}
.login-page .container-fluid .container-row .card .form-content .second-row,
.forgotten-password-page .container-fluid .container-row .card .form-content .second-row {
  padding-bottom: 0px;
}
.login-page .container-fluid .container-row .card .form-button-container,
.forgotten-password-page .container-fluid .container-row .card .form-button-container {
  margin-top: 20px;
  text-align: right;
  position: relative;
  bottom: 20px;
  right: 20px;
}
.login-page .container-fluid .container-row .card .parallax_one_copyright_content,
.forgotten-password-page .container-fluid .container-row .card .parallax_one_copyright_content {
  width: 100%;
}
.login-page .container-fluid .container-row .card .parallax_one_copyright_content .linkme-mini,
.forgotten-password-page .container-fluid .container-row .card .parallax_one_copyright_content .linkme-mini {
  width: 100%;
  color: #368ad0;
}

.axcontainer {
  cursor: pointer;
  display: none;
  position: absolute;
  z-index: 100;
  width: 50px;
  height: 70px;
  padding-left: 10px !important;
  padding-top: 20px !important;
}
@media (max-width: 700px) {
  .axcontainer {
    display: block;
  }
}
.axcontainer svg {
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
}
.axcontainer path {
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dasharray 500ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 500ms cubic-bezier(0.4, 0, 0.2, 1);
}
.axcontainer path:nth-child(1) {
  transform-origin: 36% 40%;
}
.axcontainer path:nth-child(2) {
  stroke-dasharray: 29 299;
}
.axcontainer path:nth-child(3) {
  transform-origin: 35% 63%;
}
.axcontainer path:nth-child(4) {
  stroke-dasharray: 29 299;
}
.axcontainer path:nth-child(5) {
  transform-origin: 61% 52%;
}
.axcontainer path:nth-child(6) {
  transform-origin: 62% 52%;
}

.active svg {
  transform: rotate(90deg);
}
.active path:nth-child(1) {
  transform: translateX(9px) translateY(1px) rotate(45deg);
}
.active path:nth-child(2) {
  stroke-dasharray: 225 299;
  stroke-dashoffset: -72px;
}
.active path:nth-child(3) {
  transform: translateX(9px) translateY(1px) rotate(-45deg);
}
.active path:nth-child(4) {
  stroke-dasharray: 225 299;
  stroke-dashoffset: -72px;
}
.active path:nth-child(5) {
  transform: translateX(9px) translateY(1px) rotate(-45deg);
}
.active path:nth-child(6) {
  transform: translateX(9px) translateY(1px) rotate(45deg);
}

@media (max-width: 700px) {
  .page-navbar h3 {
    margin-left: 50px;
  }
}